@use 'sass:color';

@function prism($hex) {
    @return #{color.red($hex) color.green($hex) color.blue($hex)};
}

// Typed Colors
$color-main: #fff;
$color-main-rgb: prism($color-main);
$color-base: #000;
$color-base-rgb: prism($color-base);
$color-success: #85c83e;
$color-error: #f00;
$color-error-rgb: prism($color-error);
$color-focus: #00f;
$color-focus-rgb: prism($color-focus);

// Named Colors
$color-blue: $color-focus;
$color-blue-rgb: $color-focus-rgb;
$color-dark-grey: #a9a9a9;
$color-dark-grey-rgb: prism($color-dark-grey);
$color-med-grey: #7d7f7c;
$color-light-pink: #ffefef;
$color-light-pink-rgb: prism($color-light-pink);
$color-seafoam-green: #60b1a3;

// Style Guide
$color-gray-100: #f9f9f9;
$color-gray-100-rgb: prism($color-gray-100);
$color-gray-200: #e5e5e5;
$color-gray-200-rgb: prism($color-gray-200);
$color-gray-300: #c3c3c3;
$color-gray-300-rgb: prism($color-gray-300);
$color-gray-400: #878787;
$color-gray-400-rgb: prism($color-gray-400);
$color-gray-500: #4c4c4c;
$color-gray-500-rgb: prism($color-gray-500);
$color-gray-600: #3e3e3e;
$color-gray-600-rgb: prism($color-gray-600);
$color-gray-700: #313131;
$color-gray-700-rgb: prism($color-gray-700);
$color-gray-800: #272727;
$color-gray-800-rgb: prism($color-gray-800);
$color-gray-900: #202020;
$color-gray-900-rgb: prism($color-gray-900);
$color-gray-1000: #141414;
$color-gray-1000-rgb: prism($color-gray-1000);

// Accent Colors
$color-accent-red: #ff4e4e;
$color-accent-red-rgb: prism($color-accent-red);
$color-accent-green: #787a66;
$color-accent-green-rgb: prism($color-accent-green);
$color-accent-blue: #01a3c5;
