@use '@/styles/utils' as *;

.button {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    display: flex;
    height: 3.6rem;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    touch-action: manipulation;
    width: 3.6rem;
    z-index: 1;

    &:disabled {
        svg path {
            opacity: 0.2;
        }
    }

    svg {
        height: 3rem;
        width: 3rem;
    }
}

.prev svg {
    transform: rotate(90deg);
}

.next svg {
    transform: rotate(-90deg);
}
