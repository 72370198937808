@use '@/styles/utils' as *;

.container {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 4rem;
    justify-content: center;
    padding: 10rem 5.6rem 15rem;
    position: relative;

    @media (width <= $container-width-mid) {
        flex-direction: column;
    }

    @media (width <= $container-width-min) {
        padding: 10rem 2rem;
    }
}

.images {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    width: 50%;

    > * {
        mix-blend-mode: luminosity;
    }

    @media (width <= $container-width-mid) {
        order: 1;
        width: 90%;
    }
}

.notFoundText {
    height: auto;
    width: 100%;
}

.errorText {
    height: auto;
    width: 30%;
}

.textBox {
    width: 50%;

    @media (width <= $container-width-mid) {
        order: 2;
        width: 100%;
    }
}

.secondaryTitle {
    margin-block: 2.5rem;
}

.button {
    @include button;

    border-image: url('/assets/images/tape-effect.svg');
    border-image-outset: 8px 8px 8px 8px;
    border-image-repeat: stretch stretch;
    border-image-slice: 0 0 0 0 fill;
    border-image-width: 10px 10px 10px 10px;
    color: $color-base;
    display: block;
    margin-block: 2.5rem;
    padding: 2rem 5rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: max-content;
    z-index: 2;
}

.bgImage {
    object-fit: cover;
}

.head {
    left: 0;
    position: sticky;
    right: 0;
    top: 0;
    z-index: 10;
}
