@use '@/styles/utils' as *;

.video {
    aspect-ratio: 16/9;
    height: auto;
    position: relative;
    width: 100%;
}

.disabled {
    pointer-events: none;
}

.play {
    align-items: center;
    aspect-ratio: 1;
    backdrop-filter: blur(4.5px);
    background: rgb(0 0 0 / 30%);
    border: 1px solid #ff4e4e;
    border-radius: 50%;
    box-shadow:
        0 0 22px 0 #ff4e4e,
        0 4px 36px 0 #000;
    color: $color-main;
    display: flex;
    height: 10%;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    translate: -50% -50%;

    svg {
        height: 40%;
        width: 40%;
    }

    &:hover {
        color: $color-base;
    }
}
