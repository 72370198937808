@use '@/styles/utils' as *;

@keyframes play-button-animation {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(calc(var(--play-icon-size) * 3 * -1));
    }
}

.heroHeader {
    background-color: $color-base;
}

.videoContainer {
    --tear-height: 45px;

    aspect-ratio: 16/9;
    position: relative;

    @media (width >= 1440px) {
        padding-inline: 1.5rem;
    }
}

.loader {
    aspect-ratio: 16/9;
}

.slider {
    cursor: grab;
    overflow: hidden;
    width: 100%;
}

.sliderContainer {
    display: flex;

    & > * {
        flex: 0 0 100%;
    }
}

.video {
    // If we don't set a max-width then the preview container
    // generated by react-player will continue to grow on Safari
    max-width: 100%;
    position: relative;
}

.playContainer {
    --play-icon-size: 124px;

    aspect-ratio: 1 /1;
    inset: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    width: var(--play-icon-size);
    z-index: 3;

    @media (width <= 900px) {
        --play-icon-size: 50px;
    }
}

.frostedGlass {
    backdrop-filter: blur(6px);
    background-color: rgb(20 20 20 / 30%);
    border-radius: 50%;
    inset: 0;
    position: absolute;
    z-index: -1;
}

.play {
    @media (prefers-reduced-motion: no-preference) {
        animation: play-button-animation 0.5s steps(3) infinite;
    }

    aspect-ratio: 1;
    background: url('/assets/images/play-button-sprite.svg');
    background-position: top left;
    background-repeat: no-repeat;
    display: inline-block;
    margin: auto;

    // Absolute is needed because safari won't take the parents height
    position: absolute;
    width: calc(var(--play-icon-size) * 3);
}

.tearEffect {
    height: var(--tear-height);
    inline-size: 100%;
    left: 0;
    position: absolute;
    right: 0;

    &.hidden {
        display: none;
    }

    path {
        fill: #000;
    }
}

.topTear {
    --tear-height: 25px;

    path {
        fill: #121212;
    }

    top: 0;
}

.bottomTear {
    bottom: 0;
}

.thumbsViewport {
    --thumbs-slide-spacing: 1.5rem;

    overflow: hidden;
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    margin-left: calc(var(--thumbs-slide-spacing) * -1);
    padding-block: 1.5rem;

    @media (width >= 1440px) {
        padding-inline: 7rem;
    }
}

.itemFrame {
    flex: 0 0 30%;
    position: relative;
    width: 100%;
}

.imageFrame {
    opacity: 0;
    transition: opacity 0.1s;

    &:not(.active):hover {
        opacity: 1;
    }

    &.active {
        opacity: 1;
    }
}

.thumbnail {
    height: auto;
    width: 100%;
}
