@use '@/styles/utils.scss' as *;

.banners {
    background: $color-base;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.banner {
    align-items: center;
    background-color: rgba($color-seafoam-green, 0.15);
    color: $color-seafoam-green;
    display: flex;
    gap: 2rem;
    justify-content: center;
    padding: 2rem 5rem;

    @media (max-width: $container-width-min) {
        padding: 1rem 2rem;
    }
}

.alertBanner {
    background-color: #331010;

    .innerBanner::before {
        background: $color-base;
    }

    > * {
        color: $color-accent-red;
    }
}

.textBtnWrapper {
    display: flex;
    flex: 1;
    gap: 1.6rem;
    justify-content: center;

    @media (max-width: $container-width-min) {
        flex-direction: column;
        gap: 0.5rem;
    }
}

.headingText {
    font-family: $fonts-poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.55;
    text-align: center;
}

.text {
    font-size: 14px;
    font-weight: 400;
    max-width: 70%;
    position: relative;
    text-align: center;

    &.clickable:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    @media (max-width: $container-width-min) {
        max-width: 100%;
    }
}

.hide {
    height: 100%;
    padding: 1rem;
    z-index: 10;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    svg {
        display: block;
        height: 1.4rem;
        width: 1.4rem;
    }
}

.buttonLink.disable {
    pointer-events: none;
}

.cta {
    flex-shrink: 0;

    a {
        font-family: $fonts-saira;
        font-size: 16px;
        font-weight: 400;
        height: 100%;
        letter-spacing: 1.25px;
        line-height: 1.3;
        text-align: center;
        width: 100%;
    }

    > * {
        color: $color-main;
    }

    &:hover {
        opacity: 0.8;
    }
}

.headingLink {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
}
