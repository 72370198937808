@use '@/styles/utils' as *;

.tapeBox {
    --rotation-degree: 2deg;

    margin-inline: 2rem;
    position: relative;
    transform: rotate(var(--rotation-degree));
    z-index: 3;

    &.redactedWrapper {
        transform: none;
    }

    &.flip {
        --rotation-degree: -2deg;

        svg {
            transform: scaleX(-1);
        }
    }
}

.title {
    padding: 4rem 2rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(var(--rotation-degree));
}

.tape {
    color: transparent;
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
}

.redacted {
    height: auto;
    inset: 0;
    margin-inline: auto;
    max-width: 700px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
}

.paperBox {
    --box-spacer: 8rem;

    margin-block-start: calc(var(--box-spacer) * -1);
    padding: var(--box-spacer) 4.8rem 4.8rem;
    position: relative;

    @media (width <= 500px) {
        --box-spacer: 4rem;

        padding: var(--box-spacer) 3rem 4.8rem;
    }

    &.vertical {
        background-image: url('/assets/images/paper-bg-notear.png');
        margin-block: -4rem 12rem;

        &::before,
        &::after {
            aspect-ratio: 16 / 1;
            background-image: url('/assets/images/paper-top-tear.png');
            background-size: 100%;
            content: '';
            height: auto;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &::before {
            bottom: 99.99%;
        }

        &::after {
            top: 99.99%;
            transform: rotate(180deg);
        }

        img {
            display: none;
        }
    }
}

.paperContent {
    color: $color-gray-1000;
    position: relative;

    h3 {
        margin-block-end: 3.2rem;
    }

    p {
        margin-block-end: 1rem;
    }
}
