@use '@/styles/utils' as *;

.borderWrapper {
    padding-block: 2.7rem;
    position: relative;

    &::after {
        background: url('/assets/images/faq-underline.svg');
        bottom: 0;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
    }
}

.title {
    h6 {
        color: $color-main;
        margin-inline-end: 2rem;
    }
}

.button {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    text-align: left;
    transition: 0.3s;
    width: 100%;

    &[aria-expanded='true'] {
        .icon {
            transform: rotate(180deg);
        }
    }

    &:hover,
    &:focus-visible {
        border-bottom-color: currentcolor;
        color: $color-focus;
    }
}

.icon {
    transition: 0.3s fill;
}

.collapse {
    color: $color-gray-300;
    display: grid;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    grid-template-rows: 0fr;
    height: auto;
    letter-spacing: 0.5px;
    line-height: 1.45;
    transition: grid-template-rows 0.3s ease-out;

    &.active {
        grid-template-rows: 1fr;
    }
}

.collapseInner {
    color: $color-gray-200;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    // Prevents the child from animating at a different speed than the parent
    grid-row: 1 / span 2;
    letter-spacing: 0.5px;
    line-height: 1.45; /* 23.2px */
    overflow: clip;
    padding-block-start: 2.7rem;
}
