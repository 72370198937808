@use '@/styles/utils' as *;

.wrapper {
    --buffer: 120px;

    margin-block-start: (calc(var(--buffer) * -1));
    padding: 10rem;
    position: relative;
    z-index: 3;

    @media (width <= $container-width-min) {
        padding: 10rem 2rem;
    }
}

.paperBoxContainer {
    .paperBoxContent {
        z-index: 2;
    }

    .tapeBox {
        max-width: 100%;
        width: max-content;

        h3 {
            padding: 4rem 6rem;
        }
    }
}

.bgImage {
    clip-path: polygon(0 10px, 100% calc(var(--buffer) - 10px), 100% 100%, 0 100%);
    object-fit: cover;
}

.tearEffect {
    fill: $color-base;
    height: var(--buffer);
    left: 0;
    position: absolute;
    top: 0;
    translate: 0 0%;
    width: 100%;
}

.keyFeaturesList {
    display: grid;
    gap: 4rem 10rem;
    grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
    margin-top: 5rem;
    padding: 3rem 5rem;

    @media (width <= $container-width-min) {
        padding: 3rem 0;
    }
}

.keyFeature {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template: 'icon title' '. description' auto / min-content 1fr;
}

.skull {
    grid-area: icon;
    height: 3.5rem;
    width: 3.5rem;

    @media (width <= $container-width-min) {
        height: 2.5rem;
        width: 2.5rem;
    }
}

.featureTitle {
    @include title-h5;

    font-family: $fonts-saira;
    grid-area: title;
}

.description {
    @include break-word;

    grid-area: description;
}
