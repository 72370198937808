@use '@/styles/utils' as *;

.faqsSection {
    --margin-adjustment: 13rem;
    --buffer: 120px;

    margin-block-start: (calc(var(--buffer) * -1));
    position: relative;
    z-index: 4;
}

.backgroundWrapper {
    align-items: center;
    display: flex;
    padding: 3.2rem;
    position: relative;
    z-index: 4;

    @media (width <= 900px) {
        padding: 1.5rem;
    }

    @media (width >= 1440px) {
        padding-inline: 6rem;
    }
}

.layout {
    --margin-adjustment: 20rem;

    background: linear-gradient(to top, rgb(0 0 0 / 40%), rgb(0 0 0 / 60%));
    margin-block: var(--margin-adjustment);
    padding: 3.2rem;
    width: 100%;
}

.tapeBox {
    --rotation-degree: 2deg;

    margin-bottom: 3.3rem;
    margin-top: -10rem;
    max-width: 285px;
    position: relative;
    transform: rotate(var(--rotation-degree));
    z-index: 3;
}

.tape {
    color: transparent;
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;

    path {
        fill: $color-main;
    }
}

.title {
    color: $color-gray-1000;
    font-size: 3.3rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 1.3;
    padding: 4rem 2rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(calc(var(--rotation-degree)));
}

.backgroundImage {
    clip-path: polygon(0 calc(var(--buffer) - 10px), 100% 10px, 100% 100%, 0 100%);
    object-fit: cover;
}

.tearEffect {
    fill: #000;
    height: var(--buffer);
    left: 0;
    position: absolute;
    scale: -1 1;
    top: 0;
    translate: 0 0%;
    width: 100%;
}
