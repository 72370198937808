// Container
$container-padding: 60px;
$container-width-max: 1920px;
$container-width-mid: 1200px;
$container-width-min: 900px;
$container-width-mobile: 500px;
$container-width-mobile-small: 370px;

// Fonts
$fonts-noto: 'Noto Sans TC', 'Noto Sans KR', 'Noto Sans', sans-serif;
$fonts-saira: var(--saira-font), $fonts-noto;
$fonts-poppins: var(--poppins-font), $fonts-noto;
