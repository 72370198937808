@use '@/styles/utils' as *;

.wrapper {
    flex: 1;
    padding-block: 8rem 15rem;
    padding-inline: 6rem;
    position: relative;

    @media (width <= $container-width-min) {
        padding-block: 4rem 2rem;
        padding-inline: 2rem;
    }
}

.paperBox {
    margin-inline: auto;
    max-width: 108rem;

    .paperBoxTape {
        margin-inline: auto;
        max-width: 100%;
        width: max-content;

        h3 {
            padding: 4rem 6rem;

            @media (width <= 500px) {
                font-size: 2rem;
                padding: 4rem 1.5rem;
                word-wrap: break-word;
            }
        }
    }
}

.paperBoxContent {
    margin-inline: 4rem;

    @media (width <= $container-width-min) {
        margin-inline: 0;
    }

    h4 {
        @media (width <= 500px) {
            font-size: 1.9rem;
            word-wrap: break-word;
        }
    }
}

.description {
    margin-block-end: 3rem;
}

.bgImage {
    object-fit: cover;
    z-index: -1;
}
